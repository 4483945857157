import Component from '../core/Component';


export default class ImageCompare extends Component {
    constructor(element) {
        super(element);
        this.ref = {
            slider: null,
            viewport: null,
            img: null,
        }
        this.dragging = false;
        this.width = this.element.offsetWidth
    }

    prepare() {


        this.ref.slider.addEventListener('mousedown', ()=> {
            this.dragging = true;
        });

        this.ref.slider.addEventListener('touchstart', ()=> {
            this.dragging = true;
            document.documentElement.classList.add('stop-scrolling')
        });

        this.ref.slider.addEventListener('touchend', ()=> {
            this.dragging = false;
            document.documentElement.classList.remove('stop-scrolling')
        });



        this.ref.slider.addEventListener("touchmove", ::this.handleMouseMove);

        this.element.addEventListener('mousemove', ::this.handleMouseMove);

        window.addEventListener('mouseup', ()=>{
            this.dragging = false
            // this.element.removeEventListener('mousemove', ::this.handleMouseMove);
        })
    }
    draggingOff () {this.dragging = false}

    handleMouseMove(e){
        let x = e.pageX - this.getOffsetLeft(this.element)
        if (this.dragging) {
            this.handleMove(x, this.element);
        }
    }
    handleMove (x, element) {

        let offset = x

        if (offset < 0){
            offset = 0;
        }
        else if (offset > this.width){
            offset = this.width;
        }

        let left = "left:" +  offset + "px"
        let viewportOfsset = "transform:" + 'translate3d(' + (offset) + "px, 0, 0)"
        let negativeOffset = "transform: translate3d(" + (-1 * offset) + "px, 0, 0)"

        this.ref.slider.setAttribute("style", left);
        this.ref.viewport.setAttribute("style", viewportOfsset );
        this.ref.img.setAttribute("style", negativeOffset );
    }

    getOffsetLeft(el) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        return rect.left + scrollLeft
    }

}