import Component from "../core/Component";

const STATES = {
    active: "is-active"
}

export default class Modal extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            closeIcon: null,
            // closeButton: null
        }

        this.state = {
            active: false
        }

        this.element = element;
    }

    prepare() {
        this.ref.closeIcon.addEventListener("click", ::this.close)
        // this.ref.closeButton.addEventListener("click", ::this.close)

        this.opener = document.getElementById("open-modal")

        if (this.opener) {
            this.opener.addEventListener("click", ::this.open)
        }
    }

    open() {
        this.element.classList.add(STATES.active)

    }

    close(event) {
        this.element.classList.remove(STATES.active)

        event.preventDefault()
        if (this.onClose) {
            this.onClose()
        }
    }
}