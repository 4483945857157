import Component from '../core/Component';
import GoogleMapsLoader from 'google-maps';
import $ from 'jquery';
import debounce from 'lodash/debounce';
import { createMarker } from './Marker'
GoogleMapsLoader.KEY = 'AIzaSyA17FY2rcAHoTwDC_tLBVNU4hSamvO7ukw';

export default class ContactMap extends Component {

    constructor($element) {
        super($element);

        this.latlng = null;
        this.isReady = false;

        this.$win = $(window);
        console.log(this.element)
        if(this.element.dataset.zoom){
            this.zoom = parseInt(this.element.dataset.zoom)
        }
        else{
            this.zoom = 15
        }
        console.log(this.zoom)
    }

    prepare() {

        this.coords =  {
            lat: this.element.dataset.lat,
            lng: this.element.dataset.lng
        }
        GoogleMapsLoader.load(::this.render);

        this.$win.on('resize.googleMap orientationchange.googleMap', debounce(::this.center, 200));
    }

    render(google) {
        const latlng = new google.maps.LatLng(this.coords.lat, this.coords.lng);
        this.google = google;
        this.latlng = latlng;

        this.map = new google.maps.Map(this.element, {
            center: latlng,
            zoom: this.zoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: false,
            scaleControl: false,
            gestureHandling: 'cooperative',
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: [ { "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [ { "color": "#444444" } ] }, { "featureType": "landscape", "elementType": "all", "stylers": [ { "color": "#f2f2f2" } ] }, { "featureType": "poi", "elementType": "all", "stylers": [ { "visibility": "off" } ] }, { "featureType": "road", "elementType": "all", "stylers": [ { "saturation": -100 }, { "lightness": 45 } ] }, { "featureType": "road.highway", "elementType": "all", "stylers": [ { "visibility": "simplified" } ] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [ { "color": "#ffffff" } ] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, { "featureType": "transit", "elementType": "all", "stylers": [ { "visibility": "off" } ] }, { "featureType": "water", "elementType": "all", "stylers": [ { "color": "#dde6e8" }, { "visibility": "on" } ] } ]
        });
        const Marker = createMarker(google);

        var marker = new Marker({
            position: latlng,
            map: this.map
        });

        setTimeout(() => {
            this.isReady = true;
            this.center();
        }, 0);
    }

    center() {
        if (this.isReady) {
            this.google.maps.event.trigger(this.map,'resize');
            this.map.setCenter(this.latlng);
        }
    }

    destroy() {
        this.$element.detach();
        this.map = null;
        this.$win.off('.googleMap');
    }
}