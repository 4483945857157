import Component from '../core/Component';

export default class NavOpener extends Component {
    constructor(element) {
        super(element);
    }
    prepare() {
        this.element.addEventListener('click', ::this.handleClick);
    }

    handleClick(event) {
        event.preventDefault();
        this.element.classList.toggle('is-active')
        this.target = document.getElementById('nav');
        this.nav = Component.getFromElement(this.target);
        this.nav.toggle()
    }

    close() {
        this.element.classList.remove('is-active')
        this.target = document.getElementById('nav');
        this.nav = Component.getFromElement(this.target);
        this.nav.close()
    }
}