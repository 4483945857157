import Component from '../core/Component'
import NetteForms from '../nette/NetteForms'

export default class FormOffice extends Component {
    constructor(element) {
        super(element)
        this.ref = {
            button: null
        }
    }
    prepare() {
        this.element.addEventListener('submit', this.handleSubmit)
    }
    handleSubmit = event => {
        event.preventDefault()
        const isValid = NetteForms.validateForm(this.element, true)
        if (!isValid) {
            return
        }
        $.nette.ajax({
            url: this.element.action,
            method: this.element.method || 'POST',
            data: $(this.element).serialize(),
            beforeSend: () => {
                this.ref.button.disabled = true
            },
            complete: () => {
                this.ref.button.disabled = false
            }
        })
    }
}