import Component from '../core/Component';
import Flickity from 'flickity-imagesloaded';

export default class AboutSlider extends Component {
    constructor(element) {
        super(element);
        this.ref = {
            content: null,
            next: null,
            prev: null,
        }
    }

    prepare() {
        this.flickity = new Flickity(this.ref.content, {
            cellSelector: '.AboutSlider-item',
            cellAlign: 'left',
            resize: true,
            contain: true,
            draggable: '>1',
            wrapAround: true,
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true
        });

        this.flickity.on('staticClick', function(event, pointer, cellElement, cellIndex){
            this.next();
        });

        this.ref.next.addEventListener('click', ()=>{
            this.flickity.next();
        });

        this.ref.prev.addEventListener('click', ()=>{
            this.flickity.previous();
        });
    }
}

