import Component from '../core/Component';

export const STATUS = {
    SELECTED: 'is-selected',
};

export default class Menu extends Component {

    constructor(element) {
        super(element);
        this.ref = {
            items: [],
        }
    }

    prepare() {
        this.ref.items[0].classList.add(STATUS.SELECTED)
    }

    select(id){
        this.ref.items.forEach(element =>{
            element.classList.remove(STATUS.SELECTED)
        });
        this.ref.items.forEach(element =>{
            if(element.dataset.id == id){
                element.classList.add(STATUS.SELECTED)
            }
        });

    }
}