import Component from '../core/Component'
import Cookies from 'js-cookie'

export default class ChristmasModal extends Component {
    constructor(element, options = {}) {
        super(element, options)

        this.ref = {
            modal: null
        }
    }

    prepare() {
        if (this.ref.modal) {
            this.modal = Component.getFromElement(this.ref.modal)
            this.modal.onClose = this.handleModalClose
        }

        this.canShow = Cookies.get('christmasModal') !== 'true'
        if (this.canShow) {
            this.modal.open()
        }
    }

    handleModalClose = () => {
        Cookies.set('christmasModal', true, {
            expires: 3
        })
    }
}