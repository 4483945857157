import Component from '../core/Component';
import {scrollTo} from '../services/Viewport';


export default class Order extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        if(window.location.href.split('#')[1] == 'objednavka'){
            this.open(true)
        }
    }

    open(){
        scrollTo(this.getTop(this.element) - 100)
    }

    getTop(element){
        let box = element.getBoundingClientRect();
        let body = document.body;
        let docEl = document.documentElement;
        let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        let clientTop = docEl.clientTop || body.clientTop || 0;
        let top = box.top +  scrollTop - clientTop;

        return top
    }
}