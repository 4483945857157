import Component from '../core/Component';
import {enableScrolling} from '../services/Viewport';

export const STATUS = {
    SELECTED: 'is-selected',
};

export default class OrderOpener extends Component {
    constructor(element) {
        super(element);
        this.ref = {
            items: [],
        }
    }

    prepare() {
        this.element.addEventListener('click', ()=>{
            let form = document.querySelector('.TryForm');
            if (form) {
                let FormComponent = Component.getFromElement(form)

                enableScrolling()
                FormComponent.open()

                let nav = document.getElementById('nav-openener')

                if (nav) {
                    let NavComponent = Component.getFromElement(nav)

                    if (NavComponent) {
                        NavComponent.close()
                    }
                }
            }
            else{
                document.location.href = "/#objednavka";
            }
        });
    }
}