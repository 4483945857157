import Component from '../core/Component';

const STATE = {
    activeClass: 'is-active',
}

const visibleWidth = 768;

export default class ViewMore extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            textContainer: null,
            viewToggle: null,
        }

        this.state = {
            active: false
        }
    }

    prepare() {
        this.ref.viewToggle.addEventListener('click', ::this.toggleShow);
    }

    toggleShow(event) {
        if (!this.state.active) {
            this.ref.viewToggle.classList.add(STATE.activeClass);
            this.ref.textContainer.classList.add(STATE.activeClass);
            this.state.active = true;
        } else {
            this.ref.viewToggle.classList.remove(STATE.activeClass);
            this.ref.textContainer.classList.remove(STATE.activeClass);
            this.state.active = false;
        }

        event.preventDefault();
    }
}