import Component from '../core/Component';
import { enableScrolling, disableScrolling } from '../services/Viewport';

export const STATUS = {
    OPEN: 'is-open',
    VISIBLE: 'is-visible',
};


export default class Nav extends Component {
    constructor(element) {
        super(element);

        // this.ref = {
        //    test: null,
        //    items: [],
        // }
        this.isOpen = false;
    }

    prepare() {

    }

    toggle(){
        if (!this.isOpen){
            disableScrolling();
            this.element.classList.add(STATUS.VISIBLE);
            this.element.offsetWidth;
            this.element.classList.add(STATUS.OPEN);
            this.isOpen = true
        }
        else{
            enableScrolling();
            this.element.classList.remove(STATUS.VISIBLE);
            this.element.classList.remove(STATUS.OPEN);
            this.isOpen = false
        }
    }

    open() {
        disableScrolling();
        this.element.classList.add(STATUS.VISIBLE);
        this.element.offsetWidth;
        this.element.classList.add(STATUS.OPEN);
        this.isOpen = true
    }

    close() {
        enableScrolling();
        this.element.classList.remove(STATUS.VISIBLE);
        this.element.classList.remove(STATUS.OPEN);
        this.isOpen = false
    }
}