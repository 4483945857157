import Component from '../core/Component'
import { toggleClass } from '../utils/dom'

export default class Tabs extends Component {
    constructor(element, options = {}) {
        super(element, options)

        this.ref = {
            anchors: [],
            panels: []
        }

        this.current = ''
    }

    prepare() {
        this.ref.anchors.forEach(element => element.addEventListener('click', this.handleClick))
        this.anchors = this.ref.anchors.map(element => ({
            id: element.href.split('#')[1].trim(),
            element
        }))

        this.panels = this.ref.panels.map(element => {
            const id = element.id
            element.id = `tab-${id}`

            return {
                id,
                element
            }
        })
    }

    handleClick = event => {
        event.preventDefault()

        const id = event.currentTarget.href.split('#')[1].trim()

        this.show(id)
    }

    show(id) {
        this.anchors.forEach(item => toggleClass(item.element, 'is-active', item.id === id))
        this.panels.forEach(item => toggleClass(item.element, 'is-active', item.id === id))

        const panel = this.panels.find(item => item.id === id)

        const slider = Component.getFromElement(panel.element.querySelector('[data-component="FoodSlider"]'))

        if (slider) {
            slider.resize()
        }
    }
}
