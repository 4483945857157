export const defaults = {
    position: null,
    map: null,
    className: 'Marker',
    onClick: () => {},
    template: ''
}

let Marker = null;

export function createMarker(google) {
    if (!Marker) {
        Marker = class extends google.maps.OverlayView {
            constructor(options) {
                super();

                this.options = {
                    ...defaults,
                    ...options
                }

                this.setMap(this.options.map);
            }

            handleClick(event) {
                this.options.onClick(this);
            }

            draw() {
                if (!this.element) {
                    this.element = document.createElement('div');
                    this.element.className = this.options.className;
                    this.element.innerHTML = this.options.template;

                    if (this.options.id != null) {
                        this.element.dataset.id = this.options.id;
                    }

                    this.element.addEventListener('click', ::this.handleClick);

                    const panes = this.getPanes();
                    panes.overlayMouseTarget.appendChild(this.element);
                }

                const point = this.getProjection().fromLatLngToDivPixel(this.options.position);

                if (point) {
                    this.element.style.left = `${point.x}px`;
                    this.element.style.top = `${point.y}px`;
                }
            }

            remove() {
                if (this.element) {
                    this.element.parentNode.removeChild(this.element);
                    this.element = null;
                }
            }

            getPosition() {
                return this.options.position;
            }
        }
    }

    return Marker;
}