import Component from '../core/Component';
import { scrollToElement } from '../services/Viewport';

export const STATUS = {
    SELECTED: 'is-selected',
};

export default class Switch extends Component {

    constructor(element) {
        super(element);

        this.ref = {
            selectItems: [],
            contentItems: [],
        }

        this.handleSwitch = ::this.handleSwitch

        let anchor = document.getElementById('homepageIntro')

        if (anchor) {
            this.anchor = anchor
        } else {
            this.anchor = element
        }


        this.finished = true
        this.element = element
        this.scrollMargin = 50
    }

    prepare() {
        this.ref.selectItems.forEach(element =>{
            element.addEventListener('click', ::this.handleSwitch)
        });
    }

    handleSwitch(event){
        let selectedSwitch = event.target;
        this.ref.selectItems.forEach(element =>{
            element.classList.remove(STATUS.SELECTED)
        });
        this.ref.contentItems.forEach(element =>{
            element.classList.remove(STATUS.SELECTED)
        });

        this.scrollTo(this.anchor)

        selectedSwitch.classList.add(STATUS.SELECTED)
        let selected = this.ref.selectItems.indexOf(selectedSwitch)

        this.ref.contentItems[selected].classList.add(STATUS.SELECTED)

        var map = document.getElementById('map');
        if (map){
            var mapComponent = Component.getFromElement(map)
            mapComponent.resize()
        }
    }

    scrollTo(element) {
        let offset = -element.style.paddingTop

        if (this.finished) {
            scrollToElement(element, offset, ::this.finishedScrolling)

            this.finished = false
        }
    }

    finishedScrolling() {
        this.finished = true
    }
}