import Component from '../core/Component';
import { scrollToElement } from '../services/Viewport';
import Flickity from 'flickity-sync';

export const STATUS = {
    SELECTED: 'is-active',
    DISABLED: 'is-disabled'
};

export default class Calendar extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            items: [],
            next: null,
            prev: null,
            slider: null
        }

        this.handleClick = ::this.handleClick

        this.finished = true
        this.element = element
        this.scrollMargin = 50
    }

    prepare() {
        this.ref.items.forEach(element =>{
            element.addEventListener('click', ::this.handleClick)
        });

        this.ref.items[0].classList.add(STATUS.SELECTED)

        this.flickity = new Flickity(this.ref.slider, {
            cellSelector: '.Calendar-item',
            cellAlign: 'left',
            groupCells: true,
            resize: true,
            pageDots: false,
            prevNextButtons: false,
            contain: true
        })

        this.ref.prev.addEventListener('click', ::this.prev)
        this.ref.next.addEventListener('click', ::this.next)

        this.flickity.on('change', ::this.changeSlide)
    }

    prev(event) {
        event.preventDefault()
        this.flickity.previous()
    }

    next(event) {
        event.preventDefault()
        this.flickity.next()
    }

    changeSlide(index) {
        if (index == 0) {
            this.ref.prev.classList.add(STATUS.DISABLED)
        } else {
            this.ref.prev.classList.remove(STATUS.DISABLED)
        }

        if (index == this.flickity.slides.length-1) {
            this.ref.next.classList.add(STATUS.DISABLED)
        } else {
            this.ref.next.classList.remove(STATUS.DISABLED)
        }
    }

    handleClick(event){
        let element

        if (!event.target.classList.contains('Calendar-item')){
            element = event.target.parentElement
        }

        this.ref.items.forEach(element =>{
            element.classList.remove(STATUS.SELECTED)
        });

        element.classList.add(STATUS.SELECTED)

        this.scrollTo(element)

        let menu = document.getElementById('menu')
        let menuComponent = Component.getFromElement(menu)

        menuComponent.select(element.dataset.id)
    }

    scrollTo(element) {
        let offset = -(element.style.paddingTop + this.scrollMargin)

        if (this.finished) {
            scrollToElement(element, offset, ::this.finishedScrolling)

            this.finished = false
        }
    }

    finishedScrolling() {
        this.finished = true
    }
}