import Component from '../core/Component';
import Flickity from 'flickity-sync';
import enquire from 'enquire.js';
import { queries } from '../core/config';

export default class WhySlider extends Component {
    constructor(element) {
        super(element);
        this.ref = {
            slider: null,
            next: null,
            previous: null
        }
        this.enquireHandler = {
            match: ::this.attach,
            unmatch: ::this.dettach
        }
    }

    prepare() {
        enquire.register(queries.mediumMax, this.enquireHandler);
    }

    attach(){
        this.flickity = new Flickity(this.ref.slider, {
            cellSelector: '.Why-item',
            cellAlign: 'left',
            contain: true,
            resize: true,
            pageDots: false,
            prevNextButtons: false
        });

        this.ref.next.addEventListener('click', () => {
            this.flickity.next();
        });

        this.ref.previous.addEventListener('click', () => {
            this.flickity.previous();
        });

        this.flickity.on('staticClick', function(event, pointer, cellElement, cellIndex){
            this.next();
        });

        this.flickity.on('scroll', (progress) => {
            if (progress > 0.98) {
                if (!this.ref.next.disabled) {
                    this.ref.next.disabled = true
                }
            } else if (this.ref.next.disabled) {
                this.ref.next.disabled = false
            }

            if (progress < 0.1) {
                if (!this.ref.previous.disabled) {
                    this.ref.previous.disabled = true
                }
            } else if (this.ref.previous.disabled) {
                this.ref.previous.disabled = false
            }
        })
    }

    dettach(){
        if (this.flickity){
            this.flickity.destroy()
            this.flickity = null
        }
    }

}

