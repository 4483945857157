import Component from '../core/Component';
import Flickity from 'flickity-sync';

export default class FoodSlider extends Component {
    constructor(element) {
        super(element);
        this.ref = {
            slider: null,
            next: null,
            prev: null,
            items: [],
        }

    }
    prepare() {

        this.flickity = new Flickity(this.ref.slider, {
            cellSelector: '.FoodSlider-item',
            cellAlign: 'center',
            resize: true,
            wrapAround: true,
            pageDots: false,
            prevNextButtons: false
        });

        this.flickity.on('staticClick', function(event, pointer, cellElement, cellIndex){
            this.next();
        });
        this.ref.next.addEventListener('click', ()=>{
            this.flickity.next();
        });
        this.ref.prev.addEventListener('click', ()=>{
            this.flickity.previous();
        });

    }

    resize() {
        if (this.flickity) {
            this.flickity.resize()
        }
    }
}

